

























































import UiIconPlus from "@/assets/vectors/icons/plus.svg?inline";
import UiButton from "@/components/ui/UiButton.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiIconFile from "@/assets/vectors/icons/file.svg?inline";
import UiIconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";
import { defineComponent, ref, inject } from "@/composition-api";
import useHandleAudioFile from "@/composition/useHandleAudioFile";
import UiIconClose from "../ui/UiIconClose.vue";

export default defineComponent({
  components: {
    UiIconPlus,
    UiButton,
    UiIconClose,
    UiButtonIcon,
    UiIconDelete,
    UiIconFile,
    UiIconSpinner
  },
  setup(_, { emit }) {
    const fileData = ref(Object);
    const eventBus: Vue = inject("eventBus");
    const filePath = ref("");
    const isFileUploading = ref(false);
    const isFileUploaded = ref(false);
    const {
      handleAudioFileUpload,
      handleAudioFileDelete
    } = useHandleAudioFile();

    const uploadFile = () => {
      document.getElementById("fileId").click();
    };
    const validateFilePath = filePath => {
      if (filePath) {
        isFileUploaded.value = true;
        isFileUploading.value = false;
        emit("file-changed", filePath);
        eventBus.$emit("show", {
          title: `Datei wurde vorbereitet`,
          icon: "checkmark"
        });
      } else {
        isFileUploaded.value = false;
        eventBus.$emit("show", {
          title: `Error: Datei ungültig!`
        });
      }
    };
    const onFileChange = async event => {
      fileData.value = event.target.files[0];
      isFileUploading.value = true;
      filePath.value = await handleAudioFileUpload(fileData);
      validateFilePath(filePath.value);
    };

    const dragover = event => {
      event.preventDefault();
    };
    const drop = async event => {
      event.preventDefault();
      fileData.value = event.dataTransfer.files[0];
      filePath.value = await handleAudioFileUpload(fileData);
      validateFilePath(filePath.value);
    };
    const deleteFile = () => {
      isFileUploaded.value = false;
      if (filePath.value !== "") {
        handleAudioFileDelete(filePath.value);
        eventBus.$emit("show", {
          title: `Datei wurde gelöscht`
        });
        filePath.value = "";
      }
    };
    return {
      fileData,
      filePath,
      isFileUploaded,
      isFileUploading,
      uploadFile,
      onFileChange,
      deleteFile,
      dragover,
      drop,
      handleAudioFileUpload,
      handleAudioFileDelete
    };
  }
});
