import axios from "axios";
import store from "@/store";

export default () => {
  const authJWT = store.state.auth.token;
  const handleAudioFileUpload = async audioFile => {
    const payload = new FormData();
    payload.append("file", audioFile.value);
    try {
      // This endpoint is specific for the Audio File Upload: https://confluence.mecom.de/display/EC/Audio+Hub+Resource+Service
      const response = await axios.post("/resources/api/v1/staging", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authJWT}`
        }
      });
      return response.data;
    } catch (error) {
      console.log("Audio File upload failed");
      console.log(error.response);
    }
  };
  const handleAudioFileDelete = filePath => {
    axios
      .delete(filePath, {
        headers: {
          Authorization: `Bearer ${authJWT}`
        }
      })
      .catch(error => console.log(error.response));
  };
  return { handleAudioFileUpload, handleAudioFileDelete };
};
