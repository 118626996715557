

































































































import { defineComponent, ref, computed, inject } from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiMenu from "@/components/ui/UiMenu.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiIconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import UiAutocomplete from "@dpa-id-components/ui-autocomplete";
import store from "@/store";
import useAuthentication from "@/composition/useAuthentication";
import { getWordsOrPhrases } from "@/components/AHSearch/AdvancedFilters/advancedFullTextFilters.utils";
import UiToggle from "../ui/UiToggle.vue";
import AHFileUpload from "../AHAudioUpload/AHFileUpload.vue";
import { format } from "date-fns";

export default defineComponent({
  name: "AHAudioUpload",
  components: {
    UiButton,
    UiDialog,
    UiIconClose,
    UiIconSpinner,
    UiInput,
    UiTextArea,
    UiAutocomplete,
    UiMenu,
    UiToggle,
    AHFileUpload
  },
  setup(_, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const clearFile = ref();
    const audioFileURL = ref("");
    const content_created = ref("");
    const title = ref("");
    const description = ref("");
    const persons = ref("");
    const keywords = ref("");
    const creator = ref("");
    const uploadIsLoading = ref(false);
    const isExternal = ref(true);
    const genre = ref("");
    const category = ref("");
    const audioTypes = ref([
      {
        label: "O-Ton",
        value: "aoton"
      },
      {
        label: "Interview",
        value: "atalk"
      },
      {
        label: "Atmo",
        value: "aatmo"
      },
      {
        label: "Umfrage",
        value: "aumfrage"
      }
    ]);
    const handleOptionClick = option => {
      emit("option-select", {
        value: option.label
      });
      genre.value = option.label;
      category.value = option.value;
    };

    const currentStage = computed(() => store.state.stageconfig.config.stage);
    const handleFileChange = filePath => {
      audioFileURL.value = filePath;
      // replace the host for local otherwise upload doesn't work in local env.
      if (audioFileURL.value !== "" && currentStage.value === "L O K A L") {
        const currentURL = new URL(audioFileURL.value);
        currentURL.hostname = "app.devel.dpa-audio-hub.de";
        audioFileURL.value = currentURL.href;
      }
    };

    const clearForm = () => {
      creator.value = "";
      content_created.value = "";
      genre.value = "";
      keywords.value = "";
      persons.value = "";
      title.value = "";
      description.value = "";
      isExternal.value = true;
      uploadIsLoading.value = false;
      clearFile.value.deleteFile();
    };

    const isFieldEmpty = computed(
      () =>
        audioFileURL.value === "" ||
        title.value === "" ||
        description.value === "" ||
        persons.value === "" ||
        genre.value === "" ||
        keywords.value === "" ||
        creator.value === "" ||
        content_created.value === ""
    );

    const uploadMetaData = async () => {
      const { validateAndRetrieveToken: authJWT } = useAuthentication();
      store.dispatch("audioitems/uploadAudioitem", {
        authJWT: authJWT(),
        audioObject: {
          source: creator.value,
          content_created: format(
            new Date(content_created.value),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          ),
          category: category.value,
          keywords: getWordsOrPhrases(keywords.value.toString()),
          persons: getWordsOrPhrases(persons.value.toString()),
          headline: title.value,
          description: description.value,
          external: isExternal.value,
          audio_ref: audioFileURL.value
        }
      });
      uploadIsLoading.value = true;
      setTimeout(function() {
        clearForm();
        eventBus.$emit("show", {
          title: `Ton hochgeladen`,
          icon: "checkmark"
        });
      }, 1000);
    };

    return {
      audioFileURL,
      clearFile,
      title,
      description,
      persons,
      genre,
      category,
      creator,
      content_created,
      keywords,
      audioTypes,
      isExternal,
      isFieldEmpty,
      handleOptionClick,
      uploadMetaData,
      uploadIsLoading,
      handleFileChange,
      clearForm
    };
  }
});
